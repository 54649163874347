

<template>
  <div style="width: 100%; height: 100%; background-size: 100% auto; ">

    <div style="padding: 20px 0; color: #999; font-size: 16px;">
      资产领用审批流程
    </div>



    <div style="width: 500px; margin: 0 auto;">
      <div style="text-align: center;">
        <div style="width: 500px; margin: 0 auto;">
          <el-card shadow="never">
            第一步
          </el-card>
        </div>
      </div>
      <div style="text-align: center;">
        <img :src="'./static/arrow.png'" style="width: 40px;" />
      </div>
      <div v-for="(spitem,idx) in processList" :key="idx">
        <div style="text-align: center;">
          <el-card shadow="never">
            <el-button size="mini" icon="el-icon-plus" style="opacity: 0;" circle></el-button>

            <div>
              <div class="teacheritem" v-for="(teacher,idx) in spitem.sp_users">
                <div class="headimg">
                  <img :src="teacher.avatarUrl?teacher.avatarUrl:temphead" />
                </div>
                <div class="nickname">
                  <div style="line-height: 40px;">{{teacher.name}}</div>
                </div>

              </div>
            </div>

            <div class="teacheritem" v-if="spitem.sp_type=='yuxuan'">

              <div class="nickname">
                <div style="line-height: 25px;">从预选中选择审批人</div>
              </div>
              <div>
                <div class="teacheritem" v-for="(teacher,idx) in spitem.yuxuan_users">
                  <div class="headimg">
                    <img :src="teacher.avatarUrl?teacher.avatarUrl:temphead" />
                  </div>
                  <div class="nickname">
                    <div style="line-height: 40px;">{{teacher.name}}</div>
                  </div>

                </div>
              </div>

            </div>

            <div class="teacheritem" v-if="spitem.sp_type=='juese'">

              <div class="nickname">
                <div style="line-height: 25px;">按角色<span v-if="spitem.role_id">【{{spitem.role_name}}】审批</span></div>
              </div>

            </div>

            <div class="teacheritem" v-if="spitem.sp_type=='dept'">

              <div class="nickname">
                <div style="line-height: 25px;">发起人部门领导</div>
              </div>

            </div>
            <div class="teacheritem" v-if="spitem.sp_type=='notsure'">

              <div class="nickname">
                <div style="line-height: 25px;">
                  审批人自选下级审批人

                </div>
              </div>

            </div>



            <img v-if="spitem.step_type=='and'" :src="'./static/huiqian.png'" title="会签,所有负责人全部审核才可进入下一步" class="protypeicon" />
            <img v-if="spitem.step_type=='or'" :src="'./static/huoqian.png'" title="或签,其中一人审核即可通过" class="protypeicon" />
            <img v-if="spitem.step_type=='notsure'" :src="'./static/notsure.png'" title="不确定,由审批人自选" class="protypeicon" />
            <el-button size="mini" icon="el-icon-edit" circle @click="editProcess(spitem)"></el-button>
          </el-card>
        </div>

        <div style="text-align: center;">
          <img :src="'./static/arrow.png'" style="width: 40px;" />
        </div>
      </div>

      <div style="text-align: center;">

        <el-card shadow="never">
          <el-button size="mini" icon="el-icon-plus" style="opacity: 0;" circle></el-button>
          <el-button>结束</el-button>
          <el-button size="mini" icon="el-icon-plus" circle @click="addNew"></el-button>

        </el-card>


        <el-card shadow="never">
          <div style="">抄送 <el-button size="mini" icon="el-icon-plus" circle @click="showCSDialog"></el-button>

          </div>
          <div>
            <div class="teacheritem" v-for="(teacher,idx) in cs_sys">
              <div class="headimg">
                <img :src="teacher.avatarUrl?teacher.avatarUrl:temphead" />
              </div>
              <div class="nickname">
                <div style="line-height: 40px;">{{teacher.name}}</div>


              </div>

            </div>
          </div>

        </el-card>
      </div>
    </div>






    <el-dialog title="流程编辑" :visible.sync="dialogVisible" :close-on-click-modal="false" width="700px">
      <el-form :model="EditItem" ref="EditItem" label-width="120px">



        <el-row style="padding-top: 10px;">
          <el-col :span="24">
            <el-form-item label="审批类型">

              <el-select v-model="EditItem.step_type" placeholder="请选择">
                <el-option label="或签" value="or"></el-option>
                <el-option label="会签" value="and"></el-option>
                <el-option label="审批人自选" value="notsure"></el-option>
              </el-select>
              <img v-if="EditItem.step_type=='and'" :src="'./static/huiqian.png'" title="会签,所有负责人全部审核才可进入下一步" class="protypeicon" />
              <img v-if="EditItem.step_type=='or'" :src="'./static/huoqian.png'" title="或签,其中一人审核即可通过" class="protypeicon" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="padding-top: 10px;">
          <el-col :span="24">
            <el-form-item label="审批人">

              <el-select v-model="EditItem.sp_type" placeholder="请选择">
                <el-option label="发起人部门领导" value="dept"></el-option>
                <el-option label="指定审批人员" value="one"></el-option>
                <el-option label="审批人自选下级审批人" value="notsure"></el-option>
                <el-option label="从预选中选审批人" value="yuxuan"></el-option>
                <el-option label="按角色审批" value="juese"></el-option>
              </el-select>

              <el-button v-if="EditItem.id>0" type="warning" @click="deleteProcess" title="删除该流程" circle icon="el-icon-delete"
                size="mini" style="margin-left: 15px;"></el-button>

              <span style="padding-left:20px">流程终止</span>
              <el-select v-model="EditItem.can_end" placeholder="是否可终止" style="width: 100px;">
                <el-option label="默认" :value="0"></el-option>
                <el-option label="可终止" :value="1"></el-option>
              </el-select>

            </el-form-item>
          </el-col>
        </el-row>

        <el-row style="padding-top: 10px;" v-if="EditItem.sp_type=='one'">
          选择审批人 <el-button size="mini" icon="el-icon-plus" circle @click="showOnespDialog"></el-button>

          <div style="padding-top: 10px;">
            <div class="teacheritem" v-for="(teacher,idx) in EditItem.sp_users">
              <div class="headimg">
                <img :src="teacher.avatarUrl?teacher.avatarUrl:temphead" />
              </div>
              <div class="nickname">
                <div style="line-height: 40px;">{{teacher.name}}</div>
              </div>

            </div>
          </div>
        </el-row>


        <el-row style="padding-top: 10px;" v-if="EditItem.sp_type=='yuxuan'">
          预选审批人 <el-button size="mini" icon="el-icon-plus" circle @click="showYXDialog"></el-button>
          <div>
            <div class="teacheritem" v-for="(teacher,idx) in EditItem.yuxuan_users">
              <div class="headimg">
                <img :src="teacher.avatarUrl?teacher.avatarUrl:temphead" />
              </div>
              <div class="nickname">
                <div style="line-height: 40px;">{{teacher.name}}</div>
              </div>

            </div>
          </div>

        </el-row>


        <el-row style="padding-top: 10px;" v-if="EditItem.sp_type=='juese'">
          <div style="margin-left: 20px;">
            按角色选择<span v-if="EditItem.role_id">【{{EditItem.role_name}}】审批</span>
            <el-button size="mini" icon="el-icon-plus" circle @click="showRoleDialog"></el-button>
          </div>

        </el-row>



      </el-form>
      <el-row>
        <el-col :span="22" style="text-align: right;">
          <el-button type="primary" @click="saveProcess">保存</el-button>
        </el-col>
      </el-row>

    </el-dialog>





    <DeptUser :odata="OData" module="chuchai" v-on:getUser="getUser" v-if="showUserDialog"></DeptUser>


  </div>
</template>

<script>
  import DeptUser from '../../com/deptuser.vue'

  export default {
    components: {
      DeptUser
    },
    data() {
      return {
        temphead: "./static/temphead.jpg",
        EditItem: {
          id: 0,
          process_id: 1,
          sp_type: "", //审批单人审批，部门领导审批
          step: "",
          step_type: "", //审批类型，会签and，或签or，单个人默认或签
          uid: "",
          sp_users: [],
          yuxuan_users: [],
          dept_id: 0,
          role_id: 0,
          role_name: "",
          can_end: 0
        },
        usertype: "", //选择用户时的操作
        showUserDialog: false,
        dialogVisible: false,
        processList: [],
        activeName: "zc_lingyong",
        yuxuan: [],
        cs_sys: [], //抄送
        OData: []

      }
    },
    mounted() {
      this.getList();
      this.getProcessID();

      this.getDepartment();
    },
    methods: {
      handleTabClick(e) {
        this.getList();
        this.getProcessID();

      },
      getProcessID() {
        this.$http.post("/api/form_process", {
          process_code: this.activeName
        }).then(res => {
          this.EditItem.process_id = res.data.id;
          this.cs_sys = res.data.cs_sys; //抄送
          if (this.cs_sys) {
            this.cs_sys = JSON.parse(this.cs_sys)
          } else {
            this.cs_sys = [];
          }
        })
      },


      getDepartment() {
        this.$http.post("/api/department").then(res => {
          //初始化部门和教师选中信息,这里改
          for (let dept of res.data) {
            dept.expand = false;
            for (let teacher of dept.teachers) {
              teacher.ckd = false;
            }
            for (let sdept of dept.children) {
              for (let teacher of sdept.teachers) {
                teacher.ckd = false;
              }
            }
          }
          this.orginDeptList = JSON.parse(JSON.stringify(res.data));
          this.deptList = res.data;
        });
      },
      getList() {
        this.$http.post("/api/form_process_list", {
          process_code: this.activeName
        }).then(res => {
          for (let item of res.data) {
            item.sp_users = JSON.parse(item.sp_users)
            if (item.yuxuan_users) {
              item.yuxuan_users = JSON.parse(item.yuxuan_users)
            } else {
              item.yuxuan_users = []
            }
          }
          this.processList = res.data
        })

      },
      addNew() {
        this.EditItem = {
          id: 0,
          process_id: this.EditItem.process_id,
          sp_type: "", //审批单人审批，部门领导审批
          step: "",
          step_type: "", //审批类型，会签and，或签or，单个人默认或签
          uid: "",
          sp_users: [],
          yuxuan_users: [],
          dept_id: 0,
          role_id: 0,
          role_name: "",
          can_end: 0
        }

        this.deptList = JSON.parse(JSON.stringify(this.orginDeptList));

        this.dialogVisible = true;
      },
      editProcess(e) {
        if (!e.can_end) {
          e.can_end = 0;
        }
        this.EditItem = e;
        this.deptList = JSON.parse(JSON.stringify(this.orginDeptList));
        if (e.sp_type == "one") {
          //选中已选择的用户
          //选中已选择的用户,这里改
          for (let u of e.sp_users) {
            for (let dept of this.deptList) {
              if (dept.id == u.dept_id) {
                dept.expand = true;
              }
              for (let teacher of dept.teachers) {
                if (teacher.union_id == u.union_id) {
                  teacher.ckd = true;
                }
              }
              for (let sdept of dept.children) {
                for (let teacher of sdept.teachers) {
                  if (teacher.union_id == u.union_id) {
                    teacher.ckd = true;
                  }
                }
              }
            }
          }


        }


        this.dialogVisible = true;
      },

      saveCSProcess() {

      },


      saveProcess() {
        //读取审批人

        if (this.EditItem.sp_type == "one") {


        } else if (this.EditItem.sp_type == "") {
          this.$message({
            type: 'error',
            message: '请选择审批人'
          });
          return
        } else if (this.EditItem.step_type == "notsure") {
          if (this.EditItem.sp_type != 'notsure') {
            this.$message({
              type: 'error',
              message: '您选择了审批类型为【审批人自选】，请将审批人设置为【审批人自选下级审批人】'
            });
            return
          }
        }else if (this.EditItem.sp_type == "yuxuan") {
          if(!this.EditItem.yuxuan_users||this.EditItem.yuxuan_users.length==0){
            this.$message({
              type: 'error',
              message: '请添加预选审批人'
            });
             return
          }

        } else if (this.EditItem.sp_type == "role") {
          if(!this.EditItem.role_id){
            this.$message({
              type: 'error',
              message: '请添加审批角色'
            });
            return
          }

        }

        if (!this.EditItem.step_type) {
          this.$message({
            type: 'error',
            message: '请选择审批类型'
          });
          return
        }





        let data = JSON.parse(JSON.stringify(this.EditItem));
        data.sp_users = JSON.stringify(data.sp_users);
        data.yuxuan_users = JSON.stringify(data.yuxuan_users);
        if(!data.step){
           data.step = this.processList.length + 1;
        }



        this.$http.post("/api/form_process_sp_edit", data).then(res => {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.getList();
          this.dialogVisible = false;
        })


      },
      deleteProcess() {
        this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post("/api/form_process_sp_delete", {
            id: this.EditItem.id
          }).then(res => {
            this.$message({
              type: 'success',
              message: '删除成功'
            });

            this.getList();
            setTimeout(() => {
              //重新排序
              let processList = JSON.parse(JSON.stringify(this.processList));
              for (let i = 0; i < processList.length; i++) {
                processList[i].sp_users = JSON.stringify(processList[i].sp_users);
                processList[i].step = (i + 1)
                this.$http.post("/api/form_process_sp_edit", processList[i]).then(res => {})
              }
              setTimeout(() => {
                this.getList();
              }, 1000)
            }, 1000)

            this.dialogVisible = false;
          })

        }).catch(() => {

        });
      },
      showYXDialog() {
        this.usertype = "yuxuan";
        if (this.EditItem.yuxuan_users) {
          this.OData = this.EditItem.yuxuan_users;
        }

        this.showUserDialog = true;

      },
      showCSDialog() {
        this.usertype = "chaosong";
        if (this.cs_sys) {
          this.OData = this.cs_sys;
        }
        console.log(this.OData)

        this.showUserDialog = true;
      },
      showOnespDialog() {
        this.usertype = "one";
        if (this.EditItem.sp_users) {
          this.OData =this.EditItem.sp_users;
        }
        this.showUserDialog = true;
      },
      showRoleDialog() {
        this.usertype = "role";
        this.showUserDialog = true;
      },

      getUser(e) {
        if (this.usertype == "chaosong" && e.length > 0) {
          console.log(e)
		  this.cs_sys=[]
          for (let t of e) {
            let teacher = {
              union_id: t.union_id,
              name: t.name,
              avatarUrl: t.avatarUrl,
              dept_id: t.dept_id
            }
            let ext = false
            for (let t of this.cs_sys) {
              if (t.union_id == teacher.union_id) {
                ext = true;
                break
              }
            }
            if (!ext) {
              this.cs_sys.push(teacher)
            }

          }
          console.log(this.cs_sys)
          if (this.cs_sys && this.cs_sys.length > 0) {
            this.$http.post("/api/process_cs_edit", {
              id: this.EditItem.process_id,
              cs_sys: this.cs_sys
            }).then(res => {
              this.$message({
                type: 'success',
                message: '保存成功'
              });
            })
          }
        } else if (this.usertype == "yuxuan" && e.length > 0) {

         this.EditItem.yuxuan_users = [];
          for (let t of e) {
            let teacher = {
              union_id: t.union_id,
              name: t.name,
              avatarUrl: t.avatarUrl,
              dept_id: t.dept_id
            }
            let ext = false

            for (let t of this.EditItem.yuxuan_users) {
              if (t.union_id == teacher.union_id) {
                ext = true;
                break
              }
            }
            if (!ext) {
              this.EditItem.yuxuan_users.push(teacher)
            }

          }

          if (this.EditItem.yuxuan_users && this.EditItem.yuxuan_users.length > 0) {
            // this.$http.post("/api/process_cs_edit",{id:this.EditItem.process_id,cs_sys:this.cs_sys}).then(res=>{
            //   this.$message({
            //     type: 'success',
            //     message: '保存成功'
            //   });
            // })
          }
        } else if (this.usertype == "role" && e) {
          if(e&&e.id){
            this.EditItem.role_id = e.id;
            this.EditItem.role_name = e.rolename;
          }

        } else if (this.usertype == "one" && e.length > 0) {
			 this.EditItem.sp_users=[];
          for (let t of e) {
            let teacher = {
              union_id: t.union_id,
              name: t.name,
              avatarUrl: t.avatarUrl,
              dept_id: t.dept_id
            }
            let ext = false

            for (let t of this.EditItem.sp_users) {
              if (t.union_id == teacher.union_id) {
                ext = true;
                break
              }
            }
            if (!ext) {
              this.EditItem.sp_users.push(teacher)
            }

          }
          console.log('this.EditItem.sp_users')
          console.log(this.EditItem.sp_users)

        }

        setTimeout(() => {
          this.showUserDialog = false;
        }, 300)
      }

    }
  }
</script>

<style scoped>
  .protypeicon {
    width: 30px;
    height: 30px;
    vertical-align: middle;
    margin-left: 10px;
  }

  .teacheritem {
    display: inline-block;
    padding: 8px;

    border-radius: 6px;
    margin-right: 10px;
    margin-bottom: 8px;
    cursor: pointer;
    border: 2px solid #f6f6f6;
    position: relative;
  }

  .teacheritem_ckd {
    display: inline-block;
    padding: 5px;

    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 8px;
    cursor: pointer;
    border: 1px solid #f6f6f6;
    position: relative;
  }

  .headimg_ckd {
    width: 25px;
    height: 25px;
    display: inline-block;
    vertical-align: top;
  }

  .headimg {
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: top;
  }

  .headimg img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .headimg_ckd img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  .nickname {
    display: inline-block;
    margin-left: 8px;
    vertical-align: top;
  }

  .shoucang {
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;

  }

  .itemckd {
    border: 2px solid #fe4541;
  }
</style>
